<template>
  <div class="manual-calendar-container">
    <!-- Top: Month/Year plus arrow buttons -->
    <div class="calendar-header">
      <button @click="prevMonth">&#10094;</button>
      <span>{{ monthYearLabel }}</span>
      <button @click="nextMonth">&#10095;</button>
    </div>

    <!-- Day grid: row for headers, then the days -->
    <div class="calendar-grid">
      <!-- Headers: Mo, Tu, We, Th, Fr, Sa, Su -->
      <div class="day-header" v-for="dayName in dayNames" :key="dayName">
        {{ dayName }}
      </div>

      <!-- Actual day cells (some may be blank offsets) -->
      <div class="day-cell" v-for="(day, idx) in days" :key="idx">
        <div
          class="day-number"
          :class="[
            day.eventType,  // e.g. 'past-due', 'reminder', 'due-today'
            day.isToday ? 'today' : ''
          ]"
        >
          {{ day.dayNum }}
        </div>
      </div>
    </div>

    <!-- Legend with small colored circles, not rectangles -->
    <div class="legend">
      <div class="legend-item"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="7.86162" cy="7.4771" r="7.2" fill="#FD8E82"/>
</svg><br>
Past Due</div>
      <div class="legend-item"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8.07695" cy="7.4771" r="7.2" fill="#FFB763"/>
</svg><br>
Reminder</div>
      <div class="legend-item"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="7.29228" cy="7.4771" r="7.2" fill="#50E3C2"/>
</svg><br>
Due Today</div>
    </div>
  </div>
</template>

<script>
/*
USAGE EXAMPLE in a parent component:
  <ManualCalendar
    :events="[
      { date: '2025-09-05', type: 'past-due' },
      { date: '2025-09-15', type: 'reminder' },
      { date: '2025-09-20', type: 'due-today' }
    ]"
  />
*/
export default {
  name: "ManualCalendar",
  props: {
    /* 0-based month: if omitted, uses today's. e.g. 9 => October */
    month: { type: Number, default: undefined },
    /* Full year: if omitted, uses today's. */
    year: { type: Number, default: undefined },
    /*
      events example:
      [
        { date: '2025-09-05', type: 'past-due' },
        { date: '2025-09-15', type: 'reminder' },
        { date: '2025-09-20', type: 'due-today' }
      ]
      "type" must match CSS classes in <style> (past-due, reminder, due-today).
    */
    events: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const now = new Date();
    return {
      // If user provided month/year, use them, otherwise default to today's date
      currentMonth: this.month !== undefined ? this.month : now.getMonth(),
      currentYear: this.year !== undefined ? this.year : now.getFullYear(),
    };
  },
  computed: {
    /* Monday → Sunday day headers */
    dayNames() {
      return ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
    },
    /* e.g. "September 2025" */
    monthYearLabel() {
      const months = [
        "January","February","March","April","May","June",
        "July","August","September","October","November","December"
      ];
      return `${months[this.currentMonth]} ${this.currentYear}`;
    },
    /* Build an array for each cell in the 7×(5 or 6) grid */
    days() {
      const arr = [];

      // (1) First day of current month (e.g. 2025-09-01)
      const firstOfMonth = new Date(this.currentYear, this.currentMonth, 1);
      // weekday=0 => Sunday, 1=Mon, 2=Tue...
      let startDay = firstOfMonth.getDay();
      // We want Monday=0 → so shift: if Sunday => offset=6, else offset=day-1
      const offset = (startDay === 0) ? 6 : (startDay - 1);

      // (2) Insert blank cells for offset
      for (let i = 0; i < offset; i++) {
        arr.push({ dayNum: "", eventType: "", isToday: false });
      }

      // (3) Number of days in current month
      const lastOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);
      const totalDays = lastOfMonth.getDate();

      // (4) Fill in day objects for 1..totalDays
      for (let d = 1; d <= totalDays; d++) {
        // Format: 'YYYY-MM-DD' => for matching in events
        const dayStr = `${this.currentYear}-${String(this.currentMonth + 1).padStart(2, "0")}-${String(d).padStart(2, "0")}`;
        
        // Check if there's an event for this day
        let eventClass = "";
        const foundEvent = this.events.find(e => e.date === dayStr);
        if (foundEvent) {
          // e.g. 'past-due', 'reminder', or 'due-today'
          eventClass = foundEvent.type;
        }

        // Is it real "today"?
        const realNow = new Date();
        const isToday =
          realNow.getFullYear() === this.currentYear &&
          realNow.getMonth() === this.currentMonth &&
          realNow.getDate() === d;

        arr.push({
          dayNum: d,
          eventType: eventClass,
          isToday
        });
      }

      return arr;
    },
  },
  methods: {
    // Move to previous month (decrement month/year if needed)
    prevMonth() {
      let newMonth = this.currentMonth - 1;
      let newYear = this.currentYear;
      if (newMonth < 0) {
        newMonth = 11;
        newYear--;
      }
      this.currentMonth = newMonth;
      this.currentYear = newYear;
    },
    // Move to next month
    nextMonth() {
      let newMonth = this.currentMonth + 1;
      let newYear = this.currentYear;
      if (newMonth > 11) {
        newMonth = 0;
        newYear++;
      }
      this.currentMonth = newMonth;
      this.currentYear = newYear;
    },
  }
};
</script>

<style scoped>
/* Outer container ~288×335, with white BG and rounded corners */
.manual-calendar-container {
  width: 330px;
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  font-family: "Inter", sans-serif;
  color: #132442;
  height: 400px;
}

/* Top bar: month/year label + arrow buttons */
.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.calendar-header button {
  background: none;
  border: none;
  font-size: 14px;
  color: #132442;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 4px;
}
.calendar-header button:hover {
  background-color: #f2f2f2;
}
.calendar-header span {
  font-size: 16px;
  font-weight: 700;
}

/* 7 columns: Mo, Tu, We, Th, Fr, Sa, Su */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 6px;
  column-gap: 6px;
  height: 255px;
}

/* Day-of-week headers (Mo, Tu, etc.) */
.day-header {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

/* Each day cell, height ~26 or 28px so it fits the layout */
.day-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
}

/* The day number: a small circle if it has an event or highlight for “today” */
.day-number {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  transition: background-color 0.15s;
  cursor: default;
}

/* On hover, subtle gray background */
.day-number:hover {
  background-color: #f2f2f2;
}

/* If day is real “today,” highlight with a light circle + bold text */
.today {
  background-color: #eee;
  font-weight: 700;
  color: #132442;
}

/* Event classes => fill the circle in a color, e.g. “past-due” => red circle */
.past-due {
  background-color: #f55757;
  color: #fff;
}
.reminder {
  background-color: #f5a623;
  color: #fff;
}
.due-today {
  background-color: #50e3c2;
  color: #fff;
}

/* Legend with small color circles, not rectangles */
.legend {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  font-size: 12px;
  margin-top: 10px;
  margin-right: 20px;
}
.legend-item {
  position: relative;
  padding-left: 1.5rem;
  text-align: center;
}
.legend-item::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  border-radius: 50%;
}
/* Circle colors in legend */
.legend-item.past-due::before   { background-color: #f55757; }
.legend-item.reminder::before   { background-color: #f5a623; }
.legend-item.due-today::before  { background-color: #50e3c2; }
</style>
