<template>
  <div class="card z-index-2">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between align-items-center">
        <h6>{{ currentTitle }}</h6>
        <div class="d-flex gap-2">
          <select v-model="selectedDataset" @change="applyScope">
            <option value="newEarnings">New Buisiness Income</option>
            <option value="realEarnings">Real Earnings</option>
            <option value="presentations">Presentations</option>
            <option value="earningsRatio">Earnings Ratio</option>
          </select>
          <select v-model="selectedScope" @change="applyScope">
            <option value="YTD">Year-to-Date (YTD)</option>
            <option value="MTD">Month-to-Date (MTD)</option>
            <option value="custom">Custom Range</option>
          </select>
        </div>
      </div>
      <div v-if="selectedScope === 'custom'" class="date-picker mt-2">
        <label for="startDate">Start Date:</label>
        <input type="date" id="startDate" v-model="customDateRange.start" @change="applyScope" />
        <label for="endDate" class="ms-2">End Date:</label>
        <input type="date" id="endDate" v-model="customDateRange.end" @change="applyScope" />
      </div>
      <p v-if="currentDescription" class="text-sm" v-html="currentDescription"></p>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas :id="chartId" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
//import annotationPlugin from "chartjs-plugin-annotation";
//Chart.register(annotationPlugin);
import axios from "axios";

export default {
  name: "TableSwitch",
  data() {
    return {
      selectedDataset: "newEarnings",
      selectedScope: "YTD",
      customDateRange: {
        start: "",
        end: "",
      },
      fullChartData: null,
      datasets: {
        earningsRatio: {
          title: "Earnings Ratio",
          description:
            "<i class='fa fa-chart-line text-primary'></i> <span class='font-weight-bold' style='margin-left:5px;'>Earnings Ratio<br> (New & Bound Account Premiums × 12.5%) / (All New Account Premiums × 12.5%)</span>",
          chartData: {
            labels: [],
            datasets: [
              {
                label: "Earnings Ratio",
                data: [],
                backgroundColor: "rgba(58, 65, 111, 0.8)",
                borderWidth: 2,
              },
            ],
          },
        },
        realEarnings: {
          title: "Real Earnings",
          description:
            "<i class='fa fa-dollar-sign text-success'></i> <span class='font-weight-bold' style='margin-left:5px;'>Commission from all bound accounts<br> (12.5% × Annual Premium)</span>",
          chartData: {
            labels: [],
            datasets: [
              {
                label: "Real Earnings",
                data: [],
                backgroundColor: "rgba(75, 181, 67, 0.8)",
                borderWidth: 2,
              },
            ],
          },
        },
        newEarnings: {
          title: "New Business Income",
          description:
            "<i class='fa fa-dollar-sign text-success'></i> <span class='font-weight-bold' style='margin-left:5px;'>Commission from all new-business bound accounts<br> (12.5% × Annual Premium)</span>",
          chartData: {
            labels: [],
            datasets: [
              {
                label: "New Buisiness Income",
                data: [],
                backgroundColor: "rgba(75, 181, 67, 0.8)",
                borderWidth: 2,
              },
            ],
          },
        },
        presentations: {
          title: "Presentations",
          description:
            "<i class='fa fa-presentation text-info'></i> <span class='font-weight-bold' style='margin-left:5px;'>Number of New Proposals Created per Month</span>",
          chartData: {
            labels: [],
            datasets: [
              {
                label: "Presentations",
                data: [],
                backgroundColor: "rgba(54, 162, 235, 0.8)",
                borderWidth: 2,
              },
            ],
          },
        },
      },
      height: "300",
    };
  },
  computed: {
    currentTitle() {
      return this.datasets[this.selectedDataset].title;
    },
    currentDescription() {
      return this.datasets[this.selectedDataset].description;
    },
    currentChartData() {
      return this.datasets[this.selectedDataset].chartData;
    },
    chartId() {
      return `chart-${this.selectedDataset}`;
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    selectedDataset() {
      this.applyScope(); // Trigger applyScope when dataset changes
    },
    currentChartData: {
      handler() {
        this.$nextTick(() => {
          this.renderChart();
        });
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      const token = localStorage.getItem("access_token");
      if (token) {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/chart-data`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          this.fullChartData = response.data; // Store full data
          this.applyScope(); // Apply initial scope
        } catch (error) {
          console.error("Error fetching chart data:", error);
        }
      } else {
        console.error("No access token found in localStorage.");
      }
    },
    applyScope() {
  const now = new Date();
  let startDate, endDate;

  if (this.selectedScope === "YTD") {
    startDate = new Date(now.getFullYear(), 0, 1);
    endDate = now;
  } else if (this.selectedScope === "MTD") {
    startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    endDate = now;
  } else if (
    this.selectedScope === "custom" &&
    this.customDateRange.start &&
    this.customDateRange.end
  ) {
    startDate = new Date(this.customDateRange.start);
    endDate = new Date(this.customDateRange.end);
  } else {
    console.warn("Invalid scope or incomplete custom date range.");
    return;
  }

  const filteredLabels = [];
  const filteredData = [];

  // Correctly map dataset indexes
  const datasetIndexMap = {
    realEarnings: 0,
    earningsRatio: 1,
    presentations: 2,
    newEarnings: 3, // ✅ Fix: Ensure newEarnings is properly indexed
  };

  const datasetIndex = datasetIndexMap[this.selectedDataset];

  if (datasetIndex === undefined) {
    console.warn("Invalid dataset selection:", this.selectedDataset);
    return;
  }

  this.fullChartData.labels.forEach((label, index) => {
    let labelDate;
    if (typeof label === "string" && label.match(/^\d{4}-\d{2}$/)) {
      const [year, month] = label.split("-");
      labelDate = new Date(year, parseInt(month, 10) - 1, 1);
    } else if (typeof label === "string" && label.includes("-")) {
      labelDate = new Date(label);
    } else {
      return;
    }

    if (labelDate >= startDate && labelDate <= endDate) {
      // ✅ Fix: Ensure labels are stored as "MMM YYYY" for clarity
      const formattedLabel = labelDate.toLocaleString("en-US", {
        month: "short",
        year: "numeric",
      });

      filteredLabels.push(formattedLabel);
      filteredData.push(this.fullChartData.datasets[datasetIndex].data[index] || 0); // ✅ Fix: Handle missing data
    }
  });

  // ✅ Ensure dataset updates correctly
  this.datasets[this.selectedDataset].chartData.labels = filteredLabels;
  this.datasets[this.selectedDataset].chartData.datasets[0].data = filteredData;

  this.renderChart();
},


renderChart() {
  const ctx1 = document.getElementById(this.chartId);
  if (!ctx1) return;

  const context = ctx1.getContext("2d");

  // Destroy existing chart instance if it exists
  const chartStatus = Chart.getChart(this.chartId);
  if (chartStatus) {
    chartStatus.destroy();
  }

  // Retrieve goal values dynamically based on the selected dataset
  const metrics = this.fullChartData.metrics || {};
  const selectedGoal =
    this.selectedDataset === "newEarnings"
      ? metrics.newBusinessGoalPerMonth // Assuming goal is similar for new earnings
      : this.selectedDataset === "realEarnings"
      ? metrics.realEarningsGoalPerMonth
      : this.selectedDataset === "presentations"
      ? metrics.presentationsGoalPerMonth
      : null; // No goal for earningsRatio

  // Generate goal data only if applicable
  const goalData = selectedGoal
    ? this.currentChartData.labels.map(() => selectedGoal)
    : null;

  const totalLabels = this.currentChartData.labels.length;

  // Define datasets array and include goal dataset only if applicable
  const datasets = [
    {
      label: this.getMetricLabel(this.selectedDataset), // Dynamically set the label
      data: this.currentChartData.datasets[0].data,
      backgroundColor:
        this.selectedDataset === "newEarnings"
          ? "rgba(75, 181, 67, 0.8)" // Distinct orange color for New Business
          : "rgba(75, 181, 67, 0.8)", // Default color for others
      borderWidth: 1,
      barPercentage: totalLabels === 1 ? 0.3 : 0.5, // Smaller bars for single month
      categoryPercentage: totalLabels === 1 ? 0.5 : 0.7, // Adjust spacing
    },
  ];

  // Add goal dataset only if there's a valid goal value
  if (goalData) {
    datasets.push({
      label: "Goal",
      data: goalData,
      backgroundColor: "rgba(79, 141, 249, 0.6)", // Goal bar color
      borderWidth: 1,
      barPercentage: totalLabels === 1 ? 0.3 : 0.5, // Match metric bar size
      categoryPercentage: totalLabels === 1 ? 0.5 : 0.7, // Adjust spacing
    });
  }

  new Chart(context, {
    type: "bar",
    data: {
      labels: this.currentChartData.labels,
      datasets: datasets,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          labels: {
            color: "#9ca2b7",
          },
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const datasetLabel = tooltipItem.dataset.label || "";
              const rawValue = Number(tooltipItem.raw) || 0;
              const formattedValue = rawValue.toLocaleString("en-US");
              return `${datasetLabel}: ${formattedValue}`;
            },
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            drawBorder: false,
            color: "rgba(200,200,200,0.2)",
            borderDash: [5, 5],
          },
          ticks: {
            color: "#9ca2b7",
            padding: 10,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            color: "rgba(200,200,200,0.2)",
            borderDash: [5, 5],
          },
          ticks: {
            color: "#9ca2b7",
            padding: 10,
          },
        },
      },
    },
  });
},



// Helper method to generate dynamic label
getMetricLabel(metric) {
  switch (metric) {
    case "newEarnings":
      return "New Business Income";
    case "realEarnings":
      return "Real Earnings";
    case "presentations":
      return "Presentations";
    case "earningsRatio":
      return "Earnings Ratio";
    default:
      return "Metric"; // Fallback in case of an unexpected value
  }
},






  },
};
</script>

<style scoped>
.date-picker label {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 20px;
}
.date-picker input {
  font-size: 0.9rem;
  padding: 0.3rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
</style>
