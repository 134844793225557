<template>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <div class="btn-wrapper text-center">
                  <img
                    :src="logoWhite"
                    class="w-80"
                    alt="app_logo"
                  />
                </div>

              </div>
              <div class="card-body px-lg-5 pt-0">
                <form role="form" class="text-start" @submit.prevent="handleLogin(user)">
                  <div class="mb-3">
                    <argon-input
                      id="email"
                      v-model="user.email"
                      type="email"
                      placeholder="Email"
                      aria-label="Email"
                      :value="user.email"
                    />
                    <validation-error :errors="apiValidationErrors.email" />
                  </div>
                  <div class="mb-3">
                    <argon-input
                      id="password"
                      v-model="user.password"
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                      :value="user.password"
                    />
                  </div>
                  <argon-switch id="rememberMe" name="rememberMe">
                    Remember me
                  </argon-switch>

                  <div class="text-center">
                    <argon-button
                      color="info"
                      variant="gradient"
                      full-width
                      class="my-4 mb-2"
                      >Sign in</argon-button
                    >
                  </div>

                </form>
                <router-link :to="{ name: 'Forgot Password' }" class="text-sm d-flex justify-content-center">Forgot Password?</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
import formMixin from "../../mixins/form-mixin.js"
import ValidationError from "../../components/ValidationError.vue";
import showSwal from "../../mixins/showSwal.js";
import logoWhite from "@/assets/img/logo-ct.png";

export default {
  name: "Login",
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
    ValidationError,
    //logoWhite
  },
  mixins: [formMixin, showSwal],
  data(){
    return{
      user: {
        email: null,
        password: null
      },
      logoWhite
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();

    // Retrieve email from local storage if it exists
    const storedEmail = localStorage.getItem('userEmail');

    console.log(storedEmail);
    this.user.email = storedEmail !== null ? storedEmail : null;
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    async handleLogin(user) {
      try{
        this.resetApiValidation();
        await this.$store.dispatch("auth/login", user);
        localStorage.setItem('userEmail', user.email);
        this.$router.push({name: 'Home'});
      }
      catch (error) {
  if (error.response && error.response.data && error.response.data.errors) {
    this.setApiValidation(error.response.data.errors);
    this.showSwal({
      type: "error",
      message: "Invalid credentials!"
    });
  } else {
    // Handle other types of errors, maybe logging or a generic error message
    console.error("Login Error:", error);
    this.showSwal({
      type: "error",
      message: error.message || "An error occurred during login."
    });
  }
}

    },
  },
};
</script>
